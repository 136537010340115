/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import { graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import {
  TwitterShareButton, FacebookShareButton, WhatsappShareButton, FacebookIcon, TwitterIcon, WhatsappIcon,
} from 'react-share';
import Seo from '../components/Seo';
import EpisodesLayout from '../components/EpisodesLayout';
import HighlightList from '../components/HighlightList';

import DashIcon from '../icons/Dash';
import PlayIcon from '../icons/Play';
import ClockIcon from '../icons/Clock';
import HDivider from '../icons/HDivider';
import CalenderIcon from '../icons/Calender';

export const query = graphql`
  query getcontentfulHighlightCard($slug: String!){
    contentfulHighlightCard(slug: {eq: $slug}) {
      title
      name
      description
      duration
      releaseDate
      slug
      video {
         url
        vidoePlaceholder {
          gatsbyImageData(placeholder: BLURRED)
          filename
          url
        }
      }
    }
  }
`;
const HighlighPage = ({ data }) => {
  const { contentfulHighlightCard: highlightBox } = data || {};

  const [showModal, setShowModal] = useState(false);

  return (
    <div className="bg-background">
      <div className="mx-auto max-w-[85.375rem] 3xl:mx-auto 3xl:max-w-[85.375] bg-background shadow-xl shadow-[#000000ad]">
        <EpisodesLayout>
          <Seo title={highlightBox.title} />
          <section className="">
            <div className="h-auto w-full relative flex items-center justify-center">
              <div className="md:h-auto object-cover lg:h-[600px] h-auto w-full">
                <GatsbyImage image={highlightBox.video.vidoePlaceholder.gatsbyImageData} alt="Underdog" className="object-cover block mx-auto w-full h-full z-0 group-hover:opacity-50 group-hover:shadow-lg relative" />
              </div>
              <div className="absolute -translate-x-1/2 -translate-y-1/2 mx-auto">
                <button
                  className="
                  hover:border-white
                  hover:opacity-90
                  hover:shadow-md
                  hover:scale-125
                  flex
                  items-center
                  justify-center
                  p-0 w-16 h-16
                  bg-primary
                  rounded-full
                  active:shadow-lg
                  mouse
                  shadow
                  focus:outline-none absolute mx-auto top-[60%] left-[50%] cursor-pointer hover-text-text text-white"
                  type="button"
                  onClick={() => setShowModal(true)}
                >
                  <PlayIcon />
                </button>
              </div>
              {showModal ? (
                <div
                  className="opacity-100 marker:justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                >
                  <div className="relative flex flex-col w-full h-full bg-outlined">
                    <div className="flex items-center justify-end">
                      <button
                        className="cursor-pointer background-transparent px-6 py-2 text-sm outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                      >
                        <div className="modal-close absolute top-0 right-0 cursor-pointer flex flex-col items-center mt-4 mr-4 text-black text-sm z-50">
                          <svg className="fill-current text-white" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z" />
                          </svg>
                        </div>
                      </button>
                    </div>
                    <div className="py-[2.475rem] px-[1rem] md:px-[3.125rem] h-full">
                      <iframe className="w-full h-full" src={highlightBox.video.url} title={highlightBox.video.name} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
                      {' '}

                    </div>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="py-[2.475rem] lg:px-[2rem] bg-outlined shadow-lg fd-cl">
              <div className="md:mx-auto lg:px-[7.125rem] px-[1.125rem]">
                <div className="flex flex-wrap items-center">
                  <div className="md:w-full lg:w-2/3 w-full">
                    <h1 className="block text-headerH1 text-white font-bold tracking-wide uppercase">{highlightBox.title}</h1>
                    <div className="flex items-center justify-between text-muted2">
                      <div className="flex space-x-4 md:space-x-8 my-[12px]">
                        <div className="flex cursor-pointer items-center transition hover:text-slate-600">
                          <div className="mr-[0.8rem]">
                            <ClockIcon />
                          </div>
                          <span>{highlightBox.duration}</span>
                        </div>
                        <div className="flex items-center">
                          |
                        </div>
                        <div className="flex cursor-pointer items-center transition hover:text-slate-600">
                          <div className="mr-[0.8rem]">
                            <CalenderIcon />
                          </div>
                          <span>{highlightBox.releaseDate}</span>
                        </div>
                      </div>
                    </div>
                    <p className="block text-text text-headerP">
                      {highlightBox.description}
                    </p>
                  </div>
                  <div className="md:w-full lg:w-1/3 w-full lg:px-[0.75rem] px-0 flex flex-wrap items-center">
                    <div className="mx-[26px] hidden lg:flex">
                      <HDivider />
                    </div>
                    <div className="">
                      <h3 className="text-muted2 mx-0 my-[0.5rem] font-normal">Share this episode:</h3>
                      <ul className="list-none pl-0 mt-[0.25rem] mx-[-0.25rem]">
                        <li className="flex flex-wrap px-0 md:px-[0.25rem] relative space-x-4">
                          <span className="p-1">
                            <FacebookShareButton url={`https://hightech.com/'${highlightBox.slug}`}>
                              <FacebookIcon size={32} round />
                            </FacebookShareButton>
                          </span>
                          <span className="p-1 ">
                            <TwitterShareButton url={`https://hightech.com/'${highlightBox.slug}`}>
                              <TwitterIcon size={32} round />
                            </TwitterShareButton>
                          </span>
                          <span className="p-1">
                            <WhatsappShareButton url={`https://hightech.com/'${highlightBox.slug}`}>
                              <WhatsappIcon size={32} round />
                            </WhatsappShareButton>
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="md:mx-auto lg:px-[3.125rem] px-[1.125rem]">
              <div className="lg:py-[40px] py-[20px]">
                <div className="bg-background shadow-lg md:px-0 lg:px-[6.125rem]">
                  <div className="mb-[20px] block">
                    <h1 className="text-heading font-normal relative md:text-title1 mb-[2px]">
                      Highlights
                    </h1>
                    <DashIcon />
                  </div>
                  <HighlightList />
                </div>
              </div>
            </div>
            <hr className="border-0 w-[90%] h-[1px] opacity-50  bg-gradient-to-r from-transparent via-border-color to-transparent" />
          </section>
        </EpisodesLayout>
      </div>
    </div>
  );
};
export default HighlighPage;
