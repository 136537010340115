import React from 'react';

const HDivider = () => (
  <svg width="1" height="273" viewBox="0 0 1 273" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="0.6" height="273" fill="url(#paint0_linear_86_574)" />
    <defs>
      <linearGradient id="paint0_linear_86_574" x1="0.3" y1="0" x2="0.3" y2="273" gradientUnits="userSpaceOnUse">
        <stop offset="0.197917" stopColor="#D9D9D9" stopOpacity="0" />
        <stop offset="0.520833" stopColor="#979797" stopOpacity="0.45" />
        <stop offset="0.760417" stopColor="#D9D9D9" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>

);
export default HDivider;
